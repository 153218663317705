// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { CommandBar } from 'office-ui-fabric-react';
import { DefaultButton, TextField } from 'office-ui-fabric-react';
import { Pivot, PivotItem, PivotLinkFormat } from 'office-ui-fabric-react';
import {Controlled as CodeMirror} from 'react-codemirror2';
import copy from 'copy-to-clipboard';
import API from './API.js';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { Form } from './Form.js';
import FormSchema from './FormSchema';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neat.css';
import './Config.css';
require('codemirror/mode/javascript/javascript');

type ConfigState = {
	AllowedAdministrators: string,
	UserAdministrators: string,
	ViewOnlyAdministrators: string,
	SelfAdministrators: string,
	ExternalStorageChanged: boolean,
	ExternalStorage: string,
	Config: string,
	Script: string,
	ClientVersion: string,
	HasCustomClient: boolean,
	HasProjects: boolean,
	HasAdminConsent: boolean,
	uploading: boolean,
	loading: boolean,
	success: ?string,
	error: ?string,
};

export class Config extends React.Component<{}, ConfigState> {
	editor: ?Form;
	fileInputConfig: ?HTMLInputElement;
	fileInputClient: ?HTMLInputElement;
	state = {
		AllowedAdministrators: '',
		UserAdministrators: '',
		ViewOnlyAdministrators: '',
		SelfAdministrators: '',
		ExternalStorageChanged: false,
		ExternalStorage: '',
		Config: '',
		Script: '',
		ClientVersion: '',
		HasCustomClient: false,
		HasProjects: false,
		HasAdminConsent: false,
		uploading: false,
		loading: true,
		success: null,
		error: null,
	};
	onAllowedAdministratorsChanged = (ev: any, value: string) => {
		this.setState({ AllowedAdministrators: value });
	}
	onUserAdministratorsChanged = (ev: any, value: string) => {
		this.setState({ UserAdministrators: value });
	}
	onViewOnlyAdministratorsChanged = (ev: any, value: string) => {
		this.setState({ ViewOnlyAdministrators: value });
	}
	onSelfAdministratorsChanged = (ev: any, value: string) => {
		this.setState({ SelfAdministrators: value });
	}
	onExternalStorageChanged = (ev: any, value: string) => {
		this.setState({ ExternalStorageChanged: true, ExternalStorage: value });
	}
	onScriptChanged = (editor: any, data: any, value: string) => {
		this.setState({ Script: value });
	}
	onImportConfig = (e: SyntheticInputEvent<HTMLInputElement>) => {
		e.preventDefault();
		this.setState({ error: null, success: null, uploading: true });

		var file = e.target.files[0];
		if(!file) {
			return;
		}

		if((file.type !== 'application/x-zip-compressed') && (file.name.indexOf('.zip') === -1)) {
			this.setState({ error: 'Please select a ZIP file', success: null, uploading: false });
			return;
		}

		var self = this,
			reader = new FileReader();
		reader.onloadend = function (/*e*/) {
			API.importConfig(file.type, reader.result).then(() => {
				self.setState({ error: null, success: 'Successfully imported config', uploading: false });
			}).catch((error) => {
				self.setState({ error: API.errorMessage(error), success: null, uploading: false });
			});
		};
		reader.readAsArrayBuffer(file);
	}
	onImportClient = (e: SyntheticInputEvent<HTMLInputElement>) => {
		e.preventDefault();
		this.setState({ error: null, success: null, uploading: true });

		var file = e.target.files[0];
		if(!file) {
			return;
		}

		if((file.type !== 'application/x-msdownload') && (file.name.indexOf('.exe') === -1)) {
			this.setState({ error: 'Please select a valid client executable', success: null, uploading: false });
			return;
		}

		API.importClient(file).then(() => {
			this.setState({ error: null, success: 'Successfully imported client', uploading: false });
			API.config().then((response) => { this.setState(response.data); }).catch(() => { });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null, uploading: false });
		});
	}
	onDeleteClient = () => {
		API.deleteClient().then(() => {
			this.setState({ error: null, success: 'Successfully deleted client', uploading: false });
			//API.config().then((response) => { this.setState(response.data); }).catch(() => { });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null, uploading: false });
		});
	}
	onCopyStorageToken = () => {
		API.storageToken().then((response) => {
			if(copy(response.data)) {
				this.setState({ error: null, success: 'Access token was copied to clipboard.', uploading: false });
			}
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null, uploading: false });
		});
	}
	onSave = () => {
		this.setState({ error: null, success: null });

		const config = {
			AllowedAdministrators: this.state.AllowedAdministrators,
			UserAdministrators: this.state.UserAdministrators,
			ViewOnlyAdministrators: this.state.ViewOnlyAdministrators,
			SelfAdministrators: this.state.SelfAdministrators,
			ExternalStorage: undefined
		};

		if(this.state.ExternalStorageChanged) {
			config.ExternalStorage = this.state.ExternalStorage;
		}

		API.saveConfig(config).then(() => {
			return API.saveModule('main', this.state.Script);
		}).then(() => {
			var config = this.state.Config;
			if(this.editor) {
				config = this.editor.getValue();
			}
			return API.saveModuleJSON('main', config);
		}).then(() => {
			this.setState({ error: null, success: 'Successfully saved config' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	onResetConfig = () => {
		this.setState({ Config: '' });
	}
	onUnmountForm = (editor: Form) => {
		this.setState({ Config: editor.getValue() });
	}
	render() {
		const { ClientVersion, ExternalStorage, HasCustomClient, HasProjects, AllowedAdministrators, UserAdministrators, ViewOnlyAdministrators, SelfAdministrators, Config, Script, HasAdminConsent, uploading } = this.state;
		const CodeMirrorOptions = {
			theme: 'neat',
			lineNumbers: true,
			mode: 'javascript'
		};

		var topBar = null;
		if(this.state.loading) {
			topBar = <ProgressIndicator label='Loading...'/>;
		} else if(this.state.error) {
			topBar = <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>Error - {this.state.error}</MessageBar>;
		} else if(this.state.success) {
			topBar = <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>{this.state.success}</MessageBar>;
		}

		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Server Configuration</title>
				</Helmet>        
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Server Configuration</h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ topBar }

						<input style={{display: 'none' }} type="file" ref={ c => { this.fileInputConfig = c; }} onChange={this.onImportConfig} />
						<input style={{display: 'none' }} type="file" ref={ c => { this.fileInputClient = c; }} onChange={this.onImportClient} />
						<CommandBar isSearchBoxVisible={ false } items={ [{
							key: 'import-config',
							name: 'Import Scripts',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'Upload' },
							onClick: () => {
								if(this.fileInputConfig) {
									this.fileInputConfig.value = '';
									this.fileInputConfig.click();
								}
							}
						},{
							key: 'client',
							name: 'Client',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'Product' },
							subMenuProps: {
								items: [{
									key: 'importClient',
									name: 'Import Client',
									iconProps: { iconName: 'CloudUpload' },
									onClick: () => {
										if(this.fileInputClient) {
											this.fileInputClient.value = '';
											this.fileInputClient.click();
										}
									}
								},{
									key: 'deleteClient',
									name: 'Delete Client',
									iconProps: { iconName: 'Delete' },
									disabled: !HasCustomClient,
									onClick: this.onDeleteClient
								},{
									key: 'downloadClient',
									name: 'Download Client',
									iconProps: { iconName: 'Download' },
									href: '/api/download'
								}]
							}
						},{
							key: 'save',
							name: 'Save',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'Save' },
							onClick: this.onSave
						}] } farItems={[{
							key: 'copy_blob_token',
							name: 'Copy Storage Token',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'AzureKeyVault' },
							disabled: ExternalStorage !== '',
							title: 'Copy a read-only storage access token to the clipboard. You can use this token to access the saved data.',
							onClick: this.onCopyStorageToken
						},{
							key: 'admin_consent',
							name: 'Admin Consent',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: HasAdminConsent ? 'Accept' : 'Blocked' },
							title: HasAdminConsent ? 'Admin consent was already given. Click the button to give it again.' : 'Admin consent was not given yet.',
							href: '/admin/consent'
						}] }
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ uploading ? <ProgressIndicator label='Uploading...'/> : null }
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg4">
						<TextField readOnly label="Client Version" value={ClientVersion} disabled/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg8">
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
						<TextField label='Super Administrators (separated by a comma)' multiline autoAdjustHeight value={AllowedAdministrators} onChange={this.onAllowedAdministratorsChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
						<TextField label='Administrators (separated by a comma)' multiline autoAdjustHeight value={UserAdministrators} onChange={this.onUserAdministratorsChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
						<TextField label='View-Only Administrators (separated by a comma)' multiline autoAdjustHeight value={ViewOnlyAdministrators} onChange={this.onViewOnlyAdministratorsChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
						<TextField label='Users (separated by a comma)' multiline autoAdjustHeight value={SelfAdministrators} onChange={this.onSelfAdministratorsChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					</div>
				</div>
				{ HasProjects ? <div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<TextField label="External Storage (Blob storage container SAS URL)" value={ExternalStorage} onChange={this.onExternalStorageChanged}/>
					</div>
				</div> : null }
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Global Client Configuration <DefaultButton text="Reset" onClick={this.onResetConfig} iconProps={ { iconName: 'ClearFormatting' } }/></h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Pivot linkFormat={PivotLinkFormat.tabs}>
							<PivotItem headerText='Config'>
								<Form ref={ c => { this.editor = c; }} onUnmount={this.onUnmountForm} schema={FormSchema.config} value={Config}/>
							</PivotItem>
							<PivotItem headerText='Script'>
								<div style={{ maxWidth: "1080px" }}>
									<CodeMirror value={Script} options={CodeMirrorOptions} onBeforeChange={this.onScriptChanged}/>
								</div>
							</PivotItem>
						</Pivot>
					</div>
				</div>
			</div>
		);
	}
	componentDidMount() {
		const promises = [
			API.config().catch(() => { }),
			API.blob('config/config_main.json').catch(() => { }),
			API.blob('config/config_main.chai').catch(() => { })
		];
		Promise.all(promises).then((responses) => {
			var state = responses[0] ? responses[0].data : { HasAdminConsent: false, loading: false, Config: undefined, Script: undefined };
			state.loading = false;
			if(responses[1]) {
				state.Config = responses[1].data;
			}
			if(responses[2]) {
				state.Script = responses[2].data;
			}
			this.setState(state);
		});
	}
}

export default Config;

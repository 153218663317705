// @flow
import React from 'react';
import {TextField, MarqueeSelection, DetailsList, CheckboxVisibility, Selection, SelectionMode} from 'office-ui-fabric-react';

export const CompareStringDescIgnoreCase = (field: string, a: any, b: any) => {
	const lowerA = a[field].toLowerCase();
	const lowerB = b[field].toLowerCase();
	if (lowerA < lowerB) {
		return 1;
	}
	if (lowerA > lowerB) {
		return -1;
	}
	return 0;
};
export const CompareStringAscIgnoreCase = (field: string, a: any, b: any) => {
	const lowerA = a[field].toLowerCase();
	const lowerB = b[field].toLowerCase();
	if (lowerA < lowerB) {
		return -1;
	}
	if (lowerA > lowerB) {
		return 1;
	}
	return 0;
};
export const CompareDesc = (field: string, a: any, b: any) => {
	if (a[field] < b[field]) {
		return 1;
	}
	if (a[field] > b[field]) {
		return -1;
	}
	return 0;
};
export const CompareAsc = (field: string, a: any, b: any) => {
	if (a[field] < b[field]) {
		return -1;
	}
	if (a[field] > b[field]) {
		return 1;
	}
	return 0;
};
export const CompareDescGetValue = (getValue: (x: any)=>any, a: any, b: any) => {
	const valA = getValue(a);
	const valB = getValue(b);
	if (valA < valB) {
		return 1;
	}
	if (valA > valB) {
		return -1;
	}
	return 0;
};
export const CompareAscGetValue = (getValue: (x: any)=>any, a: any, b: any) => {
	const valA = getValue(a);
	const valB = getValue(b);
	if (valA < valB) {
		return -1;
	}
	if (valA > valB) {
		return 1;
	}
	return 0;
};
type GetCompareFuncArgs = {
	getValue: (x: any)=>any,
	isSortedDescending: bool,
	data: string,
	fieldName: string,
};
export const GetCompareFunc = (sortBy: GetCompareFuncArgs) => {
	let compare_func = null;

	if(sortBy.getValue !== undefined) {
		compare_func = sortBy.isSortedDescending
			? CompareDescGetValue.bind(null, sortBy.getValue)
			: CompareAscGetValue.bind(null, sortBy.getValue);
	} else if (sortBy.data === 'string') {
		compare_func = sortBy.isSortedDescending
			? CompareStringDescIgnoreCase.bind(null, sortBy.fieldName)
			: CompareStringAscIgnoreCase.bind(null, sortBy.fieldName);
	} else {
		compare_func = sortBy.isSortedDescending
			? CompareDesc.bind(null, sortBy.fieldName)
			: CompareAsc.bind(null, sortBy.fieldName);
	}

	return compare_func;
};

type SortableListProps = {
	columns: Array<any>,
	items: Array<any>,
	isModalSelection: boolean,
	onSelectionChanged: (Array<any>) => void
};
type SortableListState = {
	isModalSelection: ?boolean,
	columns: Array<any>,
	items: Array<any>
};

class SortableList extends React.Component<SortableListProps, SortableListState> {
	static defaultProps = {
		isModalSelection: false,
		onSelectionChanged: () => { }
	};
	_selection: Selection = new Selection({
		onSelectionChanged: () => {
			if(this.props.onSelectionChanged) {
				this.props.onSelectionChanged(this._selection.getSelection());
			}
		}
	});
	state = {
		isModalSelection: this.props.isModalSelection,
		columns: this.props.columns,
		items: this.props.items
	};
	onColumnClick = (ev: any, column: any) => {
		const { columns, items } = this.state;

		let newItems = items.slice();
		let newColumns = columns.slice();
		let currColumn = newColumns.filter((currCol) => {
			return column.key === currCol.key;
		})[0];

		newColumns.forEach((newCol) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});

		newItems = SortableList.sortItems(newItems, currColumn);
		this.setState({
			columns: newColumns,
			items: newItems
		});
	}
	static sortItems(arr: Array<any>, sortBy: any) : Array<any> {
		let fn = GetCompareFunc(sortBy);
		return arr.sort(fn);
	}
	onChangeText = (ev: any, text: string) => {
		if(this.state.columns && this.state.columns.length > 0) {
			const field = this.state.columns[0].fieldName;
			if(field !== "") {
				const searchString = text.toLowerCase();
				const items = this.props.items;
				this.setState({ items: text !== "" ? items.filter(i => i[field].toLowerCase().indexOf(searchString) > -1) : items });
			}
		}
	};
	componentWillReceiveProps(nextProps: SortableListProps) {
		if(this.props.isModalSelection !== nextProps.isModalSelection) {
			this.setState({ isModalSelection: nextProps.isModalSelection });
		}
		if(this.props.columns.length !== nextProps.columns.length) {
			this.setState({ columns: nextProps.columns });
		}
		if(this.props.items.length !== nextProps.items.length) {
			const columns = nextProps.columns || this.state.columns || this.props.columns;
			let sort_colum = columns[0];

			for(var i = 1; i < columns.length; ++i) {
				if(columns[i].isSorted) {
					sort_colum = columns[i];
					break;
				}
			}

			const items = SortableList.sortItems(nextProps.items, sort_colum);
			this.setState({ items: items });
		}
	}
	componentDidUpdate(previousProps: SortableListProps, previousState: SortableListState) {
		if (previousProps.isModalSelection !== this.props.isModalSelection) {
			this._selection.setModal(this.props.isModalSelection);
		}
	}
	render() {
		const { columns, items } = this.state;
		const { isModalSelection } = this.props;

		let field = null;
		if(columns && columns.length > 0) {
			const col = columns[0];
			if(col.data === 'string') {
				field = (
					<TextField label={`Filter by ${col.name} (${this.state.items.length} items displayed):`} onChange={this.onChangeText} />
				);
			}
		}

		return (
			<div>
				{ field }
				<MarqueeSelection selection={this._selection}>
					<DetailsList compact={true} columns={columns} items={items}
						setKey="set"
						isHeaderVisible={true}
						selectionMode={isModalSelection ? SelectionMode.multiple : SelectionMode.none}
						selection={this._selection}
						selectionPreservedOnEmptyClick={true}
						enterModalSelectionOnTouch={true}
						ariaLabelForSelectionColumn="Toggle selection"
						ariaLabelForSelectAllCheckbox="Toggle selection for all items"
						onColumnHeaderClick={this.onColumnClick}
						checkboxVisibility={CheckboxVisibility.always}
						/>
		        </MarqueeSelection>
			</div>
		);
	}
}

export default SortableList;

// @flow
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Row, Column } from './Grid';
import { CommandBar, Pivot, PivotItem, ActionButton, MessageBar, MessageBarType, TextField, Icon } from 'office-ui-fabric-react';
import { DateTime } from 'luxon';
import API from './API.js';
import { ClientLog } from './ClientLog';
import { DateTimePicker } from './DateTimePicker';
import { ClientProgressItem } from './ClientProgressItem';
import { ServerMigration } from './ServerMigration';
import { ModuleData } from './ModuleData';
import Link from './Link';
import './Client.css';

type ClientProps = {
	match: Match,
	history: RouterHistory,
};
type ClientState = {
	user: any,
	group: any,
	upn: string,
	mail: string,
	finalizeDate: ?Date,
	note: string,
	success: ?string,
	error: ?string,
	custom_type: ?string,
	serverModuleDisabled: ?boolean,
	enableResetServerDefault: boolean,
}

export class Client extends React.Component<ClientProps, ClientState> {
	serverMigration: ?ServerMigration;
	dateTimePicker: ?DateTimePicker;
	state = {
		user: {},
		group: {},
		upn: '',
		mail: '',
		finalizeDate: null,
		note: '',
		success: null,
		error: null,
		custom: null,
		server: null,
		custom_type: null,
		serverModuleDisabled: undefined,
		enableResetServerDefault: true
	};
	clientLog = React.createRef();
	onDeleteLog = this.onDeleteLog.bind(this);

	onSave = () => {
		const user = this.props.match.params.id || '';
		let data = {
			Note: this.state.note,
			UPN: undefined,
			Mail: undefined,
			Finalize: undefined,
			Data: undefined
		};

		if(this.state.finalizeDate === null) {
			data.Finalize = new Date(0);
		} else {
			data.Finalize = this.state.finalizeDate;
		}

		if(this.state.upn !== this.state.user.UPN) {
			data.UPN = this.state.upn;
		}
		if(this.state.mail !== this.state.user.Mail) {
			data.Mail = this.state.mail;
		}
		if(this.state.custom_type) {
			if(this.state.custom_type === 'servermigration' && this.serverMigration) {
				data.Data = {
					ServerMigration: this.serverMigration.GetData()
				};
			}
		}

		API.saveClient(user, data).then(() => {
			this.setState({ error: null, success: 'Sucessfully saved client' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	OnRescheduleOnboarding = () => {
		const user = this.props.match.params.id || '';
		API.rescheduleOnboarding(user).then(() => {
			this.setState({ error: null, success: 'Sucessfully rescheduled onboarding' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	OnReschedulePSTImport = () => {
		const user = this.props.match.params.id || '';
		API.reschedulePSTImport(user).then(() => {
			this.setState({ error: null, success: 'Sucessfully rescheduled PST import' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	OnUpdatePSTImportStatus = () => {
		const user = this.props.match.params.id || '';
		API.updatePSTImportStatus(user).then(() => {
			this.setState({ error: null, success: 'Sucessfully triggered PST status update' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	OnServerMigrationCommand = () => {
		const user = this.props.match.params.id || '';
		API.serverMigrationCommand(user).then(() => {
			this.setState({ error: null, success: 'Sucessfully triggered server migration restart' });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	onUpnChange = (ev: any, value: string) => {
		this.setState({upn: value});
	}
	onMailChange = (ev: any, value: string) => {
		this.setState({mail: value});
	}
	onNoteChange = (ev: any, value: string) => {
		this.setState({note: value});
	}
	onDeleteClient = () => {
		const user = this.props.match.params.id || '';
		API.removeClient(user).then(() => {
			this.props.history.push('/clients');
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: null });
		});
	}
	onDeleteLog() {
		this.clientLog.current.deleteLog();
	}
	render() {
		const { user, group, custom_type, enableResetServerDefault } = this.state;
		const userId = this.props.match.params.id;

		let ServerModuleItems = [{
			key: 'reschedule-onboarding',
			name: 'Reschedule Onboarding',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'ScheduleEventAction' },
			onClick: this.OnRescheduleOnboarding
		}, {
			key: 'reschedule-pst-import',
			name: 'Reschedule PST Import',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'AzureServiceEndpoint' },
			onClick: this.OnReschedulePSTImport
		}, {
			key: 'update-pst-import-status',
			name: 'Update PST Import Status',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'Refresh' },
			onClick: this.OnUpdatePSTImportStatus
		}];

		let customModuleContent = null;
		if(custom_type) {
			if(custom_type === 'servermigration') {
				const userData = user && user.Data && user.Data.ServerMigration ? user.Data.ServerMigration : { };
				customModuleContent = <ServerMigration
										ref={ c => { this.serverMigration = c; } }
										enableResetServerDefault={enableResetServerDefault}
										userId={userId} userData={userData}/>;
				ServerModuleItems.push({
					key: 'serverMigrationCommand',
					name: 'Unstage Server Migration Data',
					className: 'ms-CommandBarItem',
					iconProps: { iconName: 'CloudUpload' },
					onClick: this.OnServerMigrationCommand
				});
			} else {
				customModuleContent = <ModuleData type='custom' userId={userId} />;
			}
		}

		return (
			<Grid>
				<Helmet>
					<title>realmigrator - User {userId}</title>
				</Helmet>        
				<Row>
					<Column width={8}>
						<h2>{user.DisplayName||user.ObjectID}</h2>
					</Column>
				</Row>
				<Row>
					<Column width={12}>
						{ this.state.error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              				Error - {this.state.error}
						</MessageBar> : null }
						{ this.state.success ? <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
              				{ typeof(this.state.success) === 'string' ? this.state.success : 'Successfully saved group' }
						</MessageBar> : null }
						<CommandBar isSearchBoxVisible={false} items={[{
							key: 'save',
							name: 'Save',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'Save' },
							onClick: this.onSave
						}, {
							key: 'server-module',
							name: 'Server Module',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'ServerProcesses' },
							disabled: this.state.serverModuleDisabled,
							subMenuProps: {
								items: ServerModuleItems
							}
						}] } farItems={[{
							key: 'delete',
							name: 'Delete',
							className: 'ms-CommandBarItem',
							iconProps: {
								iconName: 'Delete',
								style: {
									color: 'red'
								}
							},
							onClick: this.onDeleteClient
						}] }
						/>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						Timestamp
					</Column>
					<Column width={8}>
						{ DateTime.fromISO(user.Timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						UPN
					</Column>
					<Column width={8}>
						<TextField label='' value={this.state.upn} onChange={this.onUpnChange}/>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						E-Mail
					</Column>
					<Column width={8}>
						<TextField label='' value={this.state.mail} onChange={this.onMailChange}/>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						Finalize Date
					</Column>
					<Column width={8}>
						<DateTimePicker ref={ (picker) => this.dateTimePicker = picker }
							date={ this.state.finalizeDate }
							textNotFinalizing='Use group setting'
							onChanged={ (date: ?Date) => { this.setState({ finalizeDate: date }); } }
						/>
					</Column>
					<Column width={2}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						ObjectID
					</Column>
					<Column width={8}>
						{user.ObjectID}
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						GroupID
					</Column>
					<Column width={8}>
						{user.GroupID} (Maps to <Link href={'/groups/'+group.ObjectID} text={group.DisplayName||''}/>)
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						Note
					</Column>
					<Column width={8}>
						<TextField label='' placeholder='Enter custom note' multiline rows={ 4 } value={this.state.note} onChange={this.onNoteChange}/>
					</Column>
				</Row>
				<Row>
					<Column width={12}>
						<Pivot>
							<PivotItem headerText='Progress' className="UserData">
								<p><Icon iconName='Message'/> {user.Status ? user.Status.message : ''}</p>
								{user.Status && user.Status.progress ? Object.keys(user.Status.progress).map((key, index) => <ClientProgressItem key={index} name={key} progress={ user.Status.progress[key] }/>) : null}
							</PivotItem>
							<PivotItem headerText='Inventory' className="UserData">
								<ModuleData type='inventory' userId={userId} />
							</PivotItem>
							<PivotItem headerText='File' className="UserData">
								<ModuleData type='file' userId={userId} />
							</PivotItem>
							<PivotItem headerText='PST' className="UserData">
								<ModuleData type='pst' userId={userId} />
							</PivotItem>
							<PivotItem headerText='Custom' className="UserData">
								{customModuleContent}
							</PivotItem>
							<PivotItem headerText='Server' className="UserData">
								<ModuleData type='server' userId={userId} />
							</PivotItem>
							<PivotItem headerText='Log' className="UserData">
								<ActionButton iconProps={ { iconName: 'Delete' } } onClick={this.onDeleteLog}/>
								<ActionButton iconProps={ { iconName: 'Download' } } href={`/api/admin/log/${userId}?download=true`}/>
								<ClientLog userId={userId} ref={this.clientLog}/>
							</PivotItem>
						</Pivot>
					</Column>
				</Row>
			</Grid>
		);
	}
	componentDidMount() {
		const user = this.props.match.params.id || '';

		API.client(user).then((response) => {
			this.setState({
				user: response.data,
				upn: response.data.UPN,
				mail: response.data.Mail,
				finalizeDate: response.data.Finalize ? new Date(response.data.Finalize) : null,
				note: response.data.Note
			});

			if(this.dateTimePicker) {
				this.dateTimePicker.setDate(response.data.Finalize ? new Date(response.data.Finalize) : null);
			}

			API.clientGroup(user).then((response) => {
				this.setState({group:response.data});
			}).catch(() => {
				this.setState({
					group: {
						ObjectID: '*',
						DisplayName: 'Unassigned Clients'
					}
				});
			});
		}).catch(() => {});

		API.getModuleConfiguration().then((response) => {
			const enabled = response && response.data ? response.data.server : false;
			const custom_type = response && response.data ? response.data.custom : null;
			this.setState({
				custom_type: custom_type,
				serverModuleDisabled: !enabled
			});
		}).catch(() => {})
	}
}

export default Client;
